<template>
  <div>
    <Cycles
      :first="locale[lang].AVEC.FIRST"
      :images="images"
      :check="locale[lang].AVEC.CHECK"
      :semaine="locale[lang].AVEC.SEMAINE"
      :semaineImg="semaineImg"
      :apres="locale[lang].AVEC.APRES"
      :exercices="locale[lang].AVEC.EXERCICES"
      :infoShop="locale[lang].AVEC.INFOSHOP"
    />
  </div>
</template>

<script>
import Cycles from "@/components/Cycles.vue";
export default {
  components: {
    Cycles,
  },
  data() {
    return {
      images: [
        "Cycles/Avec grupo 190.png",
        "Cycles/Avec grupo 191.png",
        "Cycles/Avec grupo 192.png",
      ],
      semaineImg: "https://da32ev14kd4yl.cloudfront.net/versioned/preparationphysiquefootball/VITESSE101%20(2).png",
    };
  },
};
</script>
